<template>
    <v-row   style = "height: 45px" class="justify-space-between align-center px-9 my-3" no-gutters>
          <v-col cols="auto">
              <v-btn 
                  id="btn-add-card" 
                  v-if="user_info['org'] != ''"
                  @click="dialogs.card.show=true"
                  class="text-capitalize" 
                  style="color:#fff" 
                  height="45px" 
                  rounded 
                  color="#E0A502" 
                  variant="flat" 
                  
              >{{LOCALIZATION[this.lang].btn_add_card}}
          </v-btn>
          <span class="d-flex align-center" v-else><svg class="mx-3" xmlns="http://www.w3.org/2000/svg" width="44" height="43" viewBox="0 0 44 43" fill="none">
                  <path d="M21.9998 3.58325C11.8798 3.58325 3.6665 11.6099 3.6665 21.4999C3.6665 31.3899 11.8798 39.4166 21.9998 39.4166C32.1198 39.4166 40.3332 31.3899 40.3332 21.4999C40.3332 11.6099 32.1198 3.58325 21.9998 3.58325ZM23.8332 30.4583H20.1665V19.7083H23.8332V30.4583ZM23.8332 16.1249H20.1665V12.5416H23.8332V16.1249Z" fill="#E0A502"/>
              </svg>
              Физические лица не могут создавать заявки</span>
              
          </v-col>
      </v-row>
    <v-card class="mx-9 mt-0" style="border-radius: 14px">
      <v-data-table-server
        id="dts-list"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sortBy"
        fixed-header
        :hide-default-footer="true"
        :header-props='{style:"background-color: var(--primary-color);color: #fff;font-weight: 500;"}'
        height = "calc(100vh - 235px)"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :loading="loading['page']"
        item-value="id"
        :search="search"
        @update:options="loadItems"


        :loading-text="this.LOCALIZATION[this.lang].loading_title"
      >
      <template #bottom></template>
      <template v-slot:no-data>{{this.LOCALIZATION[this.lang].no_data_text}}</template>
      <template v-slot:item.actions="{item}">
        <v-btn variant="text" size="small" @click="this.openSubsidyCard(item.id)" icon>
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
              <g clip-path="url(#clip0_2_549)">
                <path d="M2.625 4.375H0.875V18.375C0.875 19.3419 1.65812 20.125 2.625 20.125H16.625V18.375H2.625V4.375ZM18.375 0.875H6.125C5.15812 0.875 4.375 1.65812 4.375 2.625V14.875C4.375 15.8419 5.15812 16.625 6.125 16.625H18.375C19.3419 16.625 20.125 15.8419 20.125 14.875V2.625C20.125 1.65812 19.3419 0.875 18.375 0.875ZM18.375 14.875H6.125V2.625H18.375V14.875Z" fill="#444348"/>
              </g>
              <defs>
                <clipPath id="clip0_2_549">
                  <rect width="21" height="21" fill="white"/>
                </clipPath>
              </defs>
          </svg>
        </v-btn>
      </template>
      <template v-slot:item.status="{item}">
        <!-- Компонент для отображения статуса -->
        <m-subsidy-status :title = "item.status" :status = "item.status_code"></m-subsidy-status>
      </template>
      </v-data-table-server>

    </v-card>
    <v-row no-gutters class="mx-9 align-center justify-end pagination" style="height:86px">
        <v-col cols="auto" class="d-flex flex-row align-center">
          <span class="mr-5">{{ LOCALIZATION[this.lang].rows_on_page_text }} </span>
          <v-select class="items-per-page" flat density = 'compact'  variant="outlined" style="width:90px" bg-color="#fff" hide-details v-model="itemsPerPage" :items="itemsPerPageVariants"></v-select>
          <span class="mx-13">{{ totalItems != 0 ? (page-1) * itemsPerPage + 1 : 0}} - {{ page * itemsPerPage < totalItems ? page * itemsPerPage : totalItems }} из {{ totalItems }}</span>
        </v-col>
        <v-col cols="auto">
          <v-btn size="x-small"  class="pa-0"  :disabled="this.page==1" @click="this.page = 1"  variant="text"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
            <path d="M17.6429 15.2075L13.2442 11L17.6429 6.7925L16.2917 5.5L10.5417 11L16.2917 16.5L17.6429 15.2075ZM5.75 5.5H7.66667V16.5H5.75V5.5Z" fill="#444348"/>
            </svg>
          </v-btn>
          <v-btn class="pa-0" :ripple="false" :disabled="this.page==1"  size="x-small" @click="this.page--" variant="text">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M14.1258 15.2075L9.92742 11L14.1258 6.7925L12.8333 5.5L7.33325 11L12.8333 16.5L14.1258 15.2075Z" fill="#444348"/>
            </svg>
          </v-btn>
          <span>{{ this.page }}</span>
          <v-btn class="pa-0" size="x-small" :disabled="this.page == maxPage" @click="this.page++" variant="text">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M7.87402 15.2075L12.0724 11L7.87402 6.7925L9.16652 5.5L14.6665 11L9.16652 16.5L7.87402 15.2075Z" fill="#444348"/>
            </svg>
          </v-btn>
          <v-btn class="pa-0" size="x-small" :disabled="this.page == maxPage" @click="this.page=maxPage" variant="text">
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
              <path d="M5.35693 6.7925L9.75568 11L5.35693 15.2075L6.70818 16.5L12.4582 11L6.70818 5.5L5.35693 6.7925ZM15.3332 5.5H17.2499V16.5H15.3332V5.5Z" fill="#444348"/>
            </svg>
          </v-btn>

        </v-col>
      </v-row>
    <v-dialog v-model="dialogs.card.show" width="800">
      <m-subsidy-card v-model="dialogs.card.id"  @close="closeSubsidyCard()" @save="loadItems()"></m-subsidy-card>
    </v-dialog>

  </template>
  


  
<script>
  import MSubsidyStatus from "./MSubsidyStatus.vue"
  import MSubsidyCard from "./MSubsidyCard.vue"
  import Tour from "tour"


  const LOCALIZATION = {
      RU:{
        loading_title:        'Загрузка...',
        btn_add_card:         'Создать заявку',
        no_data_text:         'Нет данных',
        rows_on_page_text:    'Показывать на странице:',
        tc_fullname:          'ФИО',
        tc_stay_date:         'Дата прибытия',
        tc_decrease_date:     'Дата убытия',
        tc_summ:              'Сумма субсидии',
        tc_org_name:          'Организация',
        tc_status:            'Статус',
        tc_actions:           'Действия',
        hm_next:              'Далее',
        hm_previous:          'Назад',
        hm_finish:            'Завершить',
        hm_steps:{
          0:                  'Кнопка для создания новой заявки',
          1:                  'Здесь отображается список заявок, которые вы подаете в гос. орган.',
          2:                  'по умолчанию Вы авторизовываетесь как Физ.лицо, так как физические лица не могут отпралять заявки вам необходимо, сменить учетную запись на ИП'
      },

      },
      EN:{
        loading_title:        'Loading...',
        btn_add_card:         'Create an application',
        no_data_text:         'No data',
        rows_on_page_text:    'Show on page:',
        tc_fullname:          'Full name',
        tc_stay_date:         'Arrival date',
        tc_decrease_date:     'Departure date',
        tc_summ:              'Subsidy amount',
        tc_org_name:          'Organization',
        tc_status:            'Status',
        tc_actions:           'Actions',
        hm_next:              'Next',
        hm_previous:          'Back',
        hm_finish:            'Finish',
        hm_steps:{
            0:                'The button for creating a new application',
            1:                'A list of applications that you submit to a government agency is displayed here.',
            2:                'by default you are authorized as an Individual, since individuals cannot send applications you need to change your account to an Individual Entrepreneur'
        },
      },
      KZ:{
        loading_title:        'Жүктеу...',
        btn_add_card:         'Өтінім құру',
        no_data_text:         'Деректер жоқ',
        rows_on_page_text:    'Мына бетте көрсету:',
        tc_fullname:          'ТАӘ',
        tc_stay_date:         'Келу күні',
        tc_decrease_date:     'Кету күні',
        tc_summ:              'Субсидия сомасы',
        tc_org_name:          'Ұйым',
        tc_status:            'Статус',
        tc_actions:           'Әрекеттер',
        hm_next:              'Әрі қарай',
        hm_previous:          'Артқа',
        hm_finish:            'Аяқтау',
        hm_steps:{
          0:                  'Жаңа өтінім құруға арналған батырма',
          1:                  'Мұнда сіз мемлекеттік органға жіберетін өтінімдердің тізімі көрсетіледі',
          2:                  'Әдепкі қалпы бойынша Сіз  авторизациядан Жеке тұлға ретінде өтесіз, жеке тұлғалар өтінімдерді жібере алмайтындықтан сізге тіркеулік жазбаны ЖК-ге өзгерту керек'
        },
      },

  }


  export default {
    name: 'MAnimalsFragment',
    components:{
      MSubsidyCard,
      MSubsidyStatus
    },
    data: () => ({
      LOCALIZATION: Object.freeze(LOCALIZATION),
      dialogs:{
        card:{
          show:false,
          id:''
        }
      },
      loading:{
        page: true
      },
      itemsPerPage:10,
      // headers: [],
      serverItems: [],
      editedItem:{},
      page: 1,
      sortBy:[],
      totalItems: 0,
      search:"",
      search_obj:{},
      itemsPerPageVariants:[
        2, 3, 10,20,50
      ]
    }),
    computed:{
      lang(){return this.$store.getters.LANG},
      help_messages_status(){return this.$store.getters.HELP_MESSAGES_STATUS},
      maxPage(){return Math.ceil(this.totalItems/this.itemsPerPage)},
      user_info(){return this.$store.getters.USER_INFO},
      headers(){
        var headers = [
        { title: this.LOCALIZATION[this.lang].tc_fullname, key: 'fullname', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_stay_date, key: 'stay_date', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_decrease_date, key: 'decrease_date', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_summ, key: 'summ' },
        { title: this.LOCALIZATION[this.lang].tc_org_name, key: 'org_name' },
        { title: this.LOCALIZATION[this.lang].tc_status, key: 'status', align:'center' }
      ]
        headers.unshift({ title: this.LOCALIZATION[this.lang].tc_actions, key: 'actions', align: 'start', width:'100px',sortable: false })
      
        return headers
      }
    },
    mounted(){
      if(this.help_messages_status){
        if(this.user_info['org'] != '' && this.$root.help_message_current_status('MSubsidy')==undefined){
          var myTour = {
          canExit: true,
          nextText: LOCALIZATION[this.lang].hm_next,
          previousText: LOCALIZATION[this.lang].hm_previous,
          finishText:LOCALIZATION[this.lang].hm_finish,
          disableInteraction: true,
          steps: [{
          target: '#btn-add-card',
          content: LOCALIZATION[this.lang].hm_steps[0],
          }, {
          target: '#dts-list',
          content: LOCALIZATION[this.lang].hm_steps[1],
          placement: [ 'right', 'top', 'bottom', 'left' ]
          }],
        } 
        Tour.start(myTour).then(result=>{
          this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidy', status: true})
        })
      }else if((this.user_info['org'] == '' && this.$store.getters.WORKPLACES.length > 1) && this.$root.help_message_current_status('MSubsidy-fiz') == undefined){
        var myTour = {
          canExit: true,
          nextText: LOCALIZATION[this.lang].hm_next,
          previousText: LOCALIZATION[this.lang].hm_previous,
          finishText:LOCALIZATION[this.lang].hm_finish,
          disableInteraction: true,
          steps: [{
          target: '#btn-edit-workplace',
          content: LOCALIZATION[this.lang].hm_steps[2],
          },
        ],
        } 
        Tour.start(myTour).then(result=>{
          this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidy-fiz', status: true})
        })
      }
        
      }
    
    },
    watch:{
      search_obj:{
        deep:true,
        handler(oldVal,newVal){
          this.search = String(Date.now())
        }
      }, 
    },
    methods: {
      
      // Загружаем список субсидий
      loadItems () {
        var page = this.page
        var itemsPerPage = this.itemsPerPage
        this.loading['page'] = true
        var sortBy_obj = {}
        this.sortBy.forEach((element,index )=> {
            sortBy_obj[element.key]  = element.order
        });
        var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
        //Запрос данных с сервера
        this.$store.dispatch('GET_SUBSIDIES',{"page":page, "itemsPerPage":itemsPerPage,"sortBy":sortBy_obj, "search":this.search_obj, "workplace_id":workplace_id}).then(response=>{
          var result = response.data.result
          this.serverItems = result['items']
          this.totalItems = result['total']
          this.loading['page'] = false
        })
      },
      openSubsidyCard(id){
        this.dialogs.card.id = id
        this.dialogs.card.show = true
      },
      closeSubsidyCard(){
        this.dialogs.card.id = ''
        this.dialogs.card.show = false
      }
    }
  }
  
</script>
  
<style>
  .v-data-table-column--align-start{
   text-align: start;
  }
  .v-data-table__thead{
   background-color: rgb(68, 68, 68) !important;
   color: #fff;
   font-weight: 600;
  }
  
  .search-text-filed.v-text-field, 
  .search-text-filed.v-text-field .v-input__control,
  .search-text-filed.v-text-field .v-field__input {
   height: 30px;
   min-height: 30px;
  }
  .search-text-filed.v-text-field .v-field__input{
   padding-top: 0px;
   padding-bottom: 0px;
  }

  .items-per-page .v-field__append-inner, .items-per-page .v-field__input, .items-per-page .v-input__control, .items-per-page .v-field{
    height: 30px;
  }
  
  .items-per-page .v-field__input{
    padding-top:0px;
  }

  .pagination .v-btn--size-x-small{
    min-width: 0px;
  }
  
</style>