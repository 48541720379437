<template>
    <div>
        <label class="input-file">
            <input type="file" @change="onFileChanged($event)" name="file" capture
                :multiple="this.multiple">
            
            <span class="input-file-text" style="font-weight: 600">{{ this.title }}</span>
            
            <span class="input-file-btn">{{this.files.length == 0 ? LOCALIZATION[this.lang].select_file_btn : this.filesReplace ? LOCALIZATION[this.lang].select_file_btn_replace:LOCALIZATION[this.lang].select_file_btn_add }}</span>           
            <span class="input-file-text" v-if="loading_progress">
                <v-progress-circular
                :model-value="loading_progress"
                :rotate="360"
                :size="30"
                :width="5"
                style="font-size: 10px;"
                color="rgb(75, 93, 167)"
                >
                {{ loading_progress }}
                </v-progress-circular>
            </span>
            <span class="input-file-text" v-else>{{LOCALIZATION[this.lang].max_val_title}} 10 {{LOCALIZATION[this.lang].max_val_ed}}</span>
            

            
        </label>
      
      <!-- accept="image/*" -->
    </div>
    <slot name="view">
        <m-files-view-block v-model="files" :view="view"></m-files-view-block>
    </slot>
    
  </template>

<script>
import MFilesViewBlock from './MFilesViewBlock.vue';
import { v4 as uuidv4 } from 'uuid';

const LOCALIZATION = {
    ru:{
        select_file_btn:                'Выберите файл',
        select_file_btn_replace:        'Заменить файл',
        select_file_btn_add:            'Добавить файл',
        max_val_title:                  'Максимум',
        max_val_ed:                     'Мб',
    },
    kk:{
        select_file_btn:                'Файлды таңдаңыз',
        select_file_btn_replace:        'Файлды ауыстыру',
        select_file_btn_add:            'Файл қосу',
        max_val_title:                  "Максималды өлшем",
        max_val_ed:                     "Мб",
    },
    en:{
        select_file_btn:                'Select a file',
        select_file_btn_replace:        'Replace the file',
        select_file_btn_add:            'Add a file',
        max_val_title:                  'Maximum',
        max_val_ed:                     'Mb',
    },
}


export default {
    name: 'MLoadFileField',
    props: {
        modelValue:{
            type: Array,
            default:[]
        },
        multiple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        view: {
            type: String,
            default: 'none'
        },
        filesReplace:{
            type: Boolean,
            default: false
        },
        lang:{type:String, default:'ru'},
        load:{type:Boolean, default: false}
    },
    emits: ['update:modelValue','loaded'],
    data: () => ({
        LOCALIZATION: Object.freeze(LOCALIZATION),
        id: '',
    }),
    computed: {
        files:{
            get(){
                return this.modelValue
            },
            set(val){
                this.$emit('update:modelValue',val)
            }
        },
        loading_progress(){
            return this.$store.getters.LOADING_PROGRESS(this.id)
        }
    },
    watch: {},
    methods: {
        async onFileChanged(event) {
            var files = event.target.files;
            if(this.filesReplace){
                this.files = []
            }
            for (var i = 0; i < files.length; i++) {
                this.creareBase64(files[i]).then(response=>{
                    this.upload(response).then(result=>{
                        for(var elem in result){
                            this.files.push(result[elem])
                        }
                       
                    })
                    
                    this.$emit('loaded', this.files)
                });
            }

            
        },
        async creareBase64(fileObject) {
            const reader = new FileReader();
            return new Promise((reslove,reject)=>{
                reader.onloadend = () => {
                // use a regex to remove data url part
                const base64String = reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "");
                var file = {
                    name: fileObject.name,
                    size: fileObject.size,
                    mime: fileObject.type,
                    data: base64String 
                };
                // log to console
                // logs wL2dvYWwgbW9yZ...

                
                reslove(file)
                
            };
            reader.readAsDataURL(fileObject);

            })
            
            
            
        },
        // Загрука файла
        async upload(file){
            this.id = uuidv4()
            return new Promise((reslove, reject)=>{
                
                this.$store.dispatch('UPLOAD_FILES',{'files':[file],'id':this.id}).then(response=>{
                    reslove(response.data.result)
                },error=>{
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
                }
            )
            }


        )
            console.log(this.files)
        }
    },
    created() {
    },
    mounted() {
    },
    components: { MFilesViewBlock }
}

</script>

<style scoped>
.truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}
.input-file {
	position: relative;
	display: inline-block;
}
.input-file-btn {
	position: relative;
	display: inline-block;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	font-size: 14px;
	vertical-align: middle;
	color: rgb(255 255 255);
	text-align: center;
	border-radius: 4px;
	background-color: #419152;
	line-height: 13px;
	height: 25px;
	padding: 5px 20px;
	box-sizing: border-box;
	border: none;
	margin: 0;
	transition: background-color 0.2s;
}
.input-file-text {
	padding: 0 10px;
	line-height: 40px;
	display: inline-block;
}
.input-file input[type=file] {
	position: absolute;
	z-index: -1;
	opacity: 0;
	display: block;
	width: 0;
	height: 0;
}
 
/* Focus */
.input-file input[type=file]:focus + .input-file-btn {
	box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}
 
/* Hover/active */
.input-file:hover .input-file-btn {
	background-color: #59be6e;
}
.input-file:active .input-file-btn {
	background-color: #2E703A;
}
 
/* Disabled */
.input-file input[type=file]:disabled + .input-file-btn {
	background-color: #eee;
}
</style>