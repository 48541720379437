<template>
    <!-- <v-chip variant="flat" :color="status_colors[status]">{{ title }}</v-chip> -->
    <span class="status" :style="{'color':  status_colors[status]}">{{ title }}</span>
</template>

<script>

   export default {
  name: 'MSubsidyStatus',
  props:{
    status: Number,
    title: String
  },

  data:()=>({

    status_colors:{
        1:'#2E4549',
        2:'#9224A6',
        3:'#FF8C00',
        5: '#9F070A',
        4: '#00901F'

    }
  }),


}
</script>
<style lang="css" scoped>
.status{
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>