<template>
    <v-card v-if="edit" >
        <v-form ref="add_form">
            <v-card-title >{{LOCALIZATION[this.lang].card_tile_edit}}</v-card-title>
            <v-card-text v-if="loading">{{LOCALIZATION[this.lang].loading_title}}</v-card-text>
            <v-card-text style="max-height:500px; height:auto"  class="overflow-y-auto" id = "m-subsidy-card-text" v-else>
                <!--Режим редактирования-->
                <v-row no-gutters v-if="edit" >
                    <v-col cols="12" >
                            <v-form ref="subsidy_form" >
                                <v-row no-gutters>
                                <v-col>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_lastname_title}}</v-col> </v-row>
                                    <v-text-field  autocomplete="off" v-model="json_data['Content']['lastname']" :rules="[v => !!v || LOCALIZATION[this.lang].error_field_lastname_not_null]" ></v-text-field>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_firstname_title}}</v-col> </v-row>
                                    <v-text-field  autocomplete="off" v-model="json_data['Content']['firstname']" :rules="[v => !!v || LOCALIZATION[this.lang].error_field_firstname_not_null]"></v-text-field>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_patronymic}}</v-col> </v-row>
                                    <v-text-field autocomplete="off" v-model="json_data['Content']['patronymic']"  ></v-text-field>
                                </v-col> 
                                </v-row>
                                <v-row no-gutters>
                                <v-col>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_stay_date_title}}</v-col> </v-row>
                                    <v-text-field 
                                        autocomplete="off" 
                                        type="date" 
                                        :max="this.$root.currentDate()" 
                                        v-model="json_data['Content'].stay_date" 
                                        :rules="[v => !!v || LOCALIZATION[this.lang].error_field_not_null]"
                                        ></v-text-field>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_decrease_date_title}}</v-col> </v-row>
                                    <v-text-field 
                                        autocomplete="off" 
                                        type="date"
                                        :disabled="!json_data['Content'].stay_date"
                                        :min="json_data['Content'].stay_date"
                                        :max="this.$root.currentDate()" 
                                        v-model="json_data['Content'].decrease_date" 
                                        :rules="[v => !!v || LOCALIZATION[this.lang].error_field_not_null]"
                                    ></v-text-field>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_summ_title}}</v-col> </v-row>
                                    <v-text-field  
                                        autocomplete="off" 
                                        v-model="json_data['Content'].summ" 
                                        :rules="[v => !!v || LOCALIZATION[this.lang].error_field_not_null]"
                                        disabled="true"
                                    ></v-text-field>

                                    <v-row>
                                        <v-col>
                                            <v-alert type="error" text="Уважаемые туроператоры, обязательное требование прикреплять все документы по отдельности, в случае неисполнения данного требования, заявка будет отклонена"></v-alert>
                                        </v-col>
                                    </v-row>

                                    <v-switch  v-model="json_data['Content'].no_debit" :label="`${LOCALIZATION[this.lang].s_f_no_debit_title}: ${json_data['Content'].no_debit}`"  false-value="Нет" true-value="Да"  hide-details></v-switch>
                                    <v-row no-gutters class="text-subtitle-1">
                                            <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_constituent_docs_title}}</v-col> 
                                            <v-col class="font-weight-bold" cols="auto">
                                                <!-- <m-load-file-field files-replace v-model = "json_data['Content'].constituent_docs"></m-load-file-field> -->
                                                <m-files-view-block  v-model="json_data['Content'].constituent_docs"></m-files-view-block>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="text-subtitle-1">
                                            <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_license_title}}</v-col> 
                                            <v-col class="font-weight-bold" cols="auto">
                                                <!-- <m-load-file-field files-replace v-model = "json_data['Content'].license" @loaded="console.log(json_data['Content'].license)"></m-load-file-field> -->
                                                <m-files-view-block  v-model="json_data['Content'].license"></m-files-view-block>
                                            </v-col>
                                    </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                            <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_eqonaq_title}}</v-col> 
                                            <v-col class="font-weight-bold" cols="auto">
                                                <m-load-file-field files-replace load v-model = "json_data['Content'].ekonak"></m-load-file-field>
                                                <m-files-view-block  v-model="json_data['Content'].ekonak"></m-files-view-block>
                                            </v-col>
                                        </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                            <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_ruming_list_title}}</v-col> 
                                            <v-col class="font-weight-bold" cols="auto">
                                                <m-load-file-field files-replace v-model = "json_data['Content'].ruming_list"></m-load-file-field>
                                                <m-files-view-block v-model="json_data['Content'].ruming_list"></m-files-view-block>
                                            </v-col>
                                        </v-row>
                                        <v-row no-gutters class="text-subtitle-1">
                                            <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_not_resident_title}}</v-col> 
                                            <v-col  class="font-weight-bold" cols="auto">
                                                <m-load-file-field files-replace v-model = "json_data['Content'].non_resident"></m-load-file-field>
                                                <m-files-view-block v-model="json_data['Content'].non_resident"></m-files-view-block>
                                            </v-col>
                                        </v-row>
                                </v-col> 
                                </v-row>
                            </v-form>
                    </v-col>
                </v-row>
                <!--режим просмотра-->
                <v-row no-gutters v-else>
                    <v-col cols="8">
                            <v-row v-if="!this.loading" no-gutters>
                                <v-col>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_lastname_title}}</v-col>  <v-col class="font-weight-bold">{{ json_data['Content']['lastname'] }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_firstname_title}}</v-col>      <v-col class="font-weight-bold" cols="auto">{{ json_data['Content']['firstname'] }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_patronymic}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content']['patronymic'] }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_stay_date_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].stay_date }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_decrease_date_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].decrease_date }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_summ_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].summ }}</v-col></v-row>
                                    <v-row no-gutters class="text-subtitle-1"><v-col cols="4" >{{LOCALIZATION[this.lang].s_f_no_debit_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].no_debit }}</v-col></v-row>
                                    
                                    <v-row no-gutters class="text-subtitle-1">
                                        <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_constituent_docs_title}}</v-col> 
                                        <v-col class="font-weight-bold" cols="auto">
                                            <m-files-view-block  v-model="json_data['Content'].constituent_docs"></m-files-view-block>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                        <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_license_title}}</v-col> 
                                        <v-col class="font-weight-bold" cols="auto">
                                            <m-files-view-block  v-model="json_data['Content'].license"></m-files-view-block>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                        <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_ruming_list_title}}</v-col> 
                                        <v-col class="font-weight-bold" cols="auto">
                                            <m-files-view-block  v-model="json_data['Content'].ruming_list"></m-files-view-block>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                        <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_not_resident_title}}</v-col> 
                                        <v-col class="font-weight-bold" cols="auto">
                                            <m-files-view-block v-model="json_data['Content'].non_resident"></m-files-view-block>
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters class="text-subtitle-1">
                                        <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_eqonaq_title}}</v-col> 
                                        <v-col class="font-weight-bold" cols="auto">
                                            <m-files-view-block v-model="json_data['Content'].ekonak"></m-files-view-block>
                                        </v-col>
                                    </v-row>
                                    
                                </v-col> 
                            </v-row>
                            <v-row v-else>
                                <v-col>{{LOCALIZATION[this.lang].loading_title}}</v-col>
                            </v-row>
                    </v-col>
                    <v-col cols="4" style="max-height:450px; height:auto"  class="overflow-y-auto">
                        <v-row v-for="row,index in this.json_data['history']" no-gutters>
                            <v-col class="d-flex flex-column">
                                <span class="font-weight-bold">{{ getDateFromTimestamp(row.rec_date)}}</span>
                                <span>{{ row.user['OrgName'] }}</span>
                                <span>{{ row.user['UserName'] }}</span>
                                <span class="font-weight-bold">{{ row.title }}</span>
                                <span v-if="row.note" class="font-italic">{{ row.note }}</span>
                                <span class="d-flex justify-center" style="color:green; font-size: 20px;" v-if="index < this.json_data['history'].length - 1"><v-icon>mdi-menu-up</v-icon></span>
                                
                            </v-col>
                            
                        </v-row>
                    </v-col>
                </v-row>
                
            </v-card-text>
        <v-card-actions id="m-subsidy-card-acrions">
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="sign()"
            v-if="!loading && (!edit && status_code == 1)"
        >
        {{ LOCALIZATION[this.lang].btn_sign_and_send_title }}
        </v-btn>
        <v-btn
            @click="save()"
            color="green darken-1"
            text
            :disabled="Object.keys(loading_progress).length !== 0"
            v-if="edit"
        >
        {{ LOCALIZATION[this.lang].btn_save_title }}
        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="this.edit=true"
            v-if="!loading && (!edit && status_code == 1)"
        >
        {{ LOCALIZATION[this.lang].btn_edit_title }}
        </v-btn>
        <!-- Отображать только при наличии прав администратора субсидий -->
        <v-btn
            color="red darken-1"
            text
            @click="refuse_dialog = true"
            v-if="!loading && (!edit && status_code == 3) && this.outgoing == false"
        >
        {{ LOCALIZATION[this.lang].btn_refuse_title }}

        </v-btn>
        <v-btn
            color="green darken-1"
            text
            @click="approve_dialog = true"
            v-if="!loading && (!edit && status_code == 3) && this.outgoing == false "
        >
        {{ LOCALIZATION[this.lang].btn_accept_title }}
        </v-btn>
        
        <v-btn
            color="green darken-1"
            text
            @click="close()"
        >
        {{ LOCALIZATION[this.lang].btn_close_title }}
        </v-btn>
        </v-card-actions>
    </v-form>
    </v-card>
    <v-card v-else :disabled="loading" class="py-8 px-6">
        <v-btn icon="mdi-close" variant="text" class="position-absolute" style="right:10px;top:10px" @click="close()"></v-btn>
        <v-card-title class="card-title" >
            <v-row no-gutters>
                <v-col cols="5">{{LOCALIZATION[this.lang].card_tile_view}}</v-col>
                <v-col cols="5" class="text-wrap">{{ json_data['User']['OrgName'] }} </v-col>
                <v-col cols="2">
                    <v-chip>
                    <m-subsidy-status v-if="status_code" :title = "status" :status = "status_code"></m-subsidy-status>
                    </v-chip>
                </v-col>

            </v-row>
        </v-card-title>
        <v-card-text v-if="loading">{{LOCALIZATION[this.lang].loading_title}}</v-card-text>
        <v-card-text style="max-height:500px; height:auto"  class="overflow-y-auto" id = "m-subsidy-card-text" v-else>
            <!--режим просмотра-->
            <v-row no-gutters>
                <v-col cols="12">
                        <v-row v-if="!this.loading" no-gutters>
                            <v-col>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_lastname_title}}</v-col>  <v-col class="font-weight-bold">{{ json_data['Content']['lastname'] }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_firstname_title}}</v-col>      <v-col class="font-weight-bold" cols="auto">{{ json_data['Content']['firstname'] }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_patronymic}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content']['patronymic'] }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_stay_date_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].stay_date }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_decrease_date_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].decrease_date }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_summ_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].summ }}</v-col></v-row>
                                <v-row no-gutters class="text-subtitle-1"><v-col cols="5" >{{LOCALIZATION[this.lang].s_f_no_debit_title}}</v-col> <v-col class="font-weight-bold" cols="auto">{{ json_data['Content'].no_debit }}</v-col></v-row>
                                
                                <v-row no-gutters class="text-subtitle-1 align-center mt-12">
                                    <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_constituent_docs_title}}</v-col> 
                                    <v-col class="font-weight-bold" cols="auto">
                                        <m-files-view-block  v-model="json_data['Content'].constituent_docs"></m-files-view-block>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="text-subtitle-1 align-center">
                                    <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_license_title}}</v-col> 
                                    <v-col class="font-weight-bold" cols="auto">
                                        <m-files-view-block  v-model="json_data['Content'].license"></m-files-view-block>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="text-subtitle-1 align-center">
                                    <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_ruming_list_title}}</v-col> 
                                    <v-col class="font-weight-bold" cols="auto">
                                        <m-files-view-block  v-model="json_data['Content'].ruming_list"></m-files-view-block>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="text-subtitle-1 align-center" >
                                    <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_not_resident_title}}</v-col> 
                                    <v-col class="font-weight-bold" cols="auto">
                                        <m-files-view-block v-model="json_data['Content'].non_resident"></m-files-view-block>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="text-subtitle-1 align-center">
                                    <v-col cols="4" >{{LOCALIZATION[this.lang].s_f_eqonaq_title}}</v-col> 
                                    <v-col class="font-weight-bold" cols="auto">
                                        <m-files-view-block v-model="json_data['Content'].ekonak"></m-files-view-block>
                                    </v-col>
                                </v-row>
                                
                            </v-col> 
                        </v-row>
                        <v-row v-else>
                            <v-col>{{LOCALIZATION[this.lang].loading_title}}</v-col>
                        </v-row>
                </v-col>
                <v-col class="py-4" cols="12">
                    <v-divider :thickness="3"></v-divider>
                </v-col>
                <v-col cols="12" style="max-height:450px; height:auto"  class="overflow-y-auto">
                    <v-row v-for="row,index in this.json_data['history']" no-gutters>
                        <v-col cols="12">
                            {{ row.title }}
                        </v-col>
                        <v-col class="d-flex flex-column">
                            <span class="font-weight-bold">{{ getDateFromTimestamp(row.rec_date)}}</span>
                            
                        </v-col>
                        <v-col>
                            {{ row.user['OrgName'] }}
                        </v-col>
                        <v-col class="font-weight-bold">
                            {{ row.user['UserName'] }}
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>
            
        </v-card-text>
        <v-card-actions id="m-subsidy-card-acrions">
        <v-spacer></v-spacer>
        <v-row>
            <v-col v-if="!loading && (!edit && status_code == 1)">
                <v-btn
                color="var(--primary-color)"
                style="color:white;text-transform: none;"
                rounded
                variant="flat"
                width="100%"
                @click="sign()"
                
                >
                {{ LOCALIZATION[this.lang].btn_sign_and_send_title }}
                </v-btn>
            </v-col>
            <v-col v-if="!loading && (!edit && status_code == 1)">
                <v-btn
                    color="#E1AE23"
                    style="color:white;text-transform: none;"
                    rounded
                    variant="flat"
                    width="100%"
                    @click="this.edit=true"
                    
                >
                {{ LOCALIZATION[this.lang].btn_edit_title }}
                </v-btn>
            </v-col>
            <v-col v-if="!loading && (!edit && status_code == 3) && this.outgoing == false">
                <v-btn
                    color="var(--primary-color)"
                    style="color:white;text-transform: none;"
                    rounded
                    variant="flat"
                    width="100%"
                    @click="refuse_dialog = true"
                    
                >
                {{ LOCALIZATION[this.lang].btn_refuse_title }}

                </v-btn>
            </v-col>
            <v-col v-if="!loading && (!edit && status_code == 3) && this.outgoing == false ">
                <v-btn
                    color="#E1AE23"
                    style="color:white;text-transform: none;"
                    rounded
                    variant="flat"
                    width="100%"
                    @click="approve_dialog = true"
                    
                >
                {{ LOCALIZATION[this.lang].btn_accept_title }}
                </v-btn>
            </v-col>



        </v-row>
        
        
        <!-- Отображать только при наличии прав администратора субсидий -->

        
        
        </v-card-actions>
    
    </v-card>
    <v-dialog width="600" v-model="approve_dialog">
        <m-approve-subsidy v-model="id" @close="approve_dialog = false" @save="this.close()"></m-approve-subsidy>
    </v-dialog>
    <v-dialog width="600" v-model="refuse_dialog">
        <m-approve-subsidy v-model="id" @close="refuse_dialog = false"  variant="refuse" @save="this.close()"></m-approve-subsidy>
    </v-dialog>
    <!-- Модуль для подписи данных -->
    <m-nuc-sign  v-model="sign_dialog" @sign="send($event)" variant="cms" type="SIGNATURE" :data="this.sign_data"></m-nuc-sign> 
</template>

<script>
import MACRow from '@/fragments/help/components/MACRow.vue';
import MLoadFileField from "@/components/MLoadFileField.vue";
import MFilesViewBlock from "@/components/MFilesViewBlock.vue";
import MNucSign from "@miril/m-nuc-sign";
import { Base64 } from 'js-base64';
import MSubsidyStatus from './MSubsidyStatus.vue';
import MApproveSubsidy from './help/MApproveSubsidy.vue';
  import Tour from "tour"

const LOCALIZATION = {
    RU:{
        error_field_lastname_not_null:          'Поле фамилия не может быть пустым',
        error_field_lastname_not_null:          'Поле имя не может быть пустым',
        error_field_not_null:                   'Поле не может быть пустым',
        card_tile_edit:                         'Добавление записи',
        card_tile_view:                         'Карточка заявки',
        loading_title:                          'Загрузка...',
        s_f_lastname_title:                     'Фамилия',
        s_f_firstname_title:                    'Имя',
        s_f_patronymic:                         'Отчество',
        s_f_stay_date_title:                    'Дата прибытия',
        s_f_decrease_date_title:                'Дата убытия',
        s_f_summ_title:                         'Сумма субсидии',
        s_f_no_debit_title:                     'Свед. об отсутствии задолженности',
        s_f_constituent_docs_title:             'Учредительные документы',
        s_f_license_title:                      'Лицензия',
        s_f_eqonaq_title:                       'eQonaq',
        s_f_ruming_list_title:                  'Руминг лист',
        s_f_not_resident_title:                 'Договор с неризидентом',
        btn_sign_and_send_title:                'Подписать и Отправить',
        btn_save_title:                         'Сохранить',
        btn_edit_title:                         'Изменить',
        btn_refuse_title:                       'Отказать',
        btn_accept_title:                       'Принять',
        btn_close_title:                        'Закрыть',
        mes_not_constituent_docs:               'Отсутствует обязательный файл учредительных документов',
        mes_not_license:                        'Отсутствует документ Лицензия',
        mes_not_ekonak:                         'Отсутствует обязательный файл eQonaq',
        mes_not_ruming_list:                    'Отсутствует обязательный файл руминг лист',
        hm_next:                                'Далее',
        hm_previous:                            'Назад',
        hm_finish:                              'Завершить',
        hm_steps:{
            0:                                  'Для отправки заявки Вам необходимо заполнить все поля и прикрепить все файлы',
            1:                                  'Здесь отображаются действия, которые Вы можете проделать с карточкой.',
        },
    },
    KZ:{
        error_field_lastname_not_null:          'Тегіңіз өрісі бос болуы мүмкін емес',
        error_field_lastname_not_null:          'Атыңыз өрісі бос болуы мүмкін емес',
        error_field_not_null:                   'Өріс бос болуы мүмкін емес',
        card_tile_edit:                         'Жазба қосу',
        card_tile_view:                         'Өтінім карточкасы',
        loading_title:                          'Жүктеу...',
        s_f_lastname_title:                     'Тегіңіз',
        s_f_firstname_title:                    'Атыңыз',
        s_f_patronymic:                         'Әкеңіздің аты',
        s_f_stay_date_title:                    'Келу күні',
        s_f_decrease_date_title:                'Шығу күні',
        s_f_summ_title:                         'Субсидия сомасы',
        s_f_no_debit_title:                     'Берешектің жоқтығы туралы мәліметтер',
        s_f_constituent_docs_title:             "Құрылтай құжаттары",
        s_f_license_title:                      'Лицензия',
        s_f_eqonaq_title:                       'eQonaq',
        s_f_ruming_list_title:                  "Руминг тізімі",
        s_f_not_resident_title:                 'Бейрезидентпен келісім-шарт',
        btn_sign_and_send_title:                'Қол қою және жіберу',
        btn_save_title:                         'Сақтау',
        btn_edit_title:                         'Өзгерту',
        btn_refuse_title:                       'Бас тарту',
        btn_accept_title:                       'Қабылдау',
        btn_close_title:                        'Жабу',
        mes_not_constituent_docs:               'Құрылтай құжаттарының міндетті файлы жоқ',
        mes_not_license:                        'Лицензия құжаты жоқ',
        mes_not_ekonak:                         'eQonaq міндетті файлы жоқ',
        mes_not_ruming_list:                    'Руминг тізімі міндетті файлы жоқ',
        hm_next:                                'Әрі қарай',
        hm_previous:                            'Артқа',
        hm_finish:                              'Аяқтау',
        hm_steps:{
            0:                                  'Өтінімді жіберу үшін барлық өрістерді толтырып, барлық файлдарды қоса тіркеу керек',
            1:                                  'Карточкамен жасауға болатын әрекеттер осында көрсетіледі.',
        },
    },
    EN:{
        error_field_lastname_not_null:          'The last name field cannot be empty',
        error_field_lastname_not_null:          'The first name field cannot be empty',
        error_field_not_null:                   'The field cannot be empty',
        card_tile_edit:                         'Adding a record',
        card_tile_view:                         'Application card',
        loading_title:                          'Loading...',
        s_f_lastname_title:                     'Last name',
        s_f_firstname_title:                    'First name',
        s_f_patronymic:                         'Patronymic',
        s_f_stay_date_title:                    'Arrival date',
        s_f_decrease_date_title:                'Departure date',
        s_f_summ_title:                         'Subsidy amount',
        s_f_no_debit_title:                     'Information about the absence of debt',
        s_f_constituent_docs_title:             'Constituent documents',
        s_f_license_title:                      'License',
        s_f_eqonaq_title:                       'eQonaq',
        s_f_ruming_list_title:                  'Rooming list',
        s_f_not_resident_title:                 'Agreement with a non-resident',
        btn_sign_and_send_title:                'Sign and send',
        btn_save_title:                         'Save',
        btn_edit_title:                         'Edit',
        btn_refuse_title:                       'Refuse',
        btn_accept_title:                       'Accept',
        btn_close_title:                        'Close',
        mes_not_constituent_docs:               'The required file of constituent documents is missing',
        mes_not_license:                        'The License document is missing',
        mes_not_ekonak:                         'The required eQonaq file is missing',
        mes_not_ruming_list:                    'The required rooming list file is missing',
        hm_next:                                'Next',
        hm_previous:                            'Back',
        hm_finish:                              'Finish',
        hm_steps:{
            0:                                  'To submit your application, you must fill in all fields and attach all files',
            1:                                  'The actions that you can do with the card are displayed here.',
        },
    },
}

export default {
    name: 'MSubsidyCard',
    components:{
    MACRow,
    MLoadFileField,
    MFilesViewBlock,
    MNucSign,
    MSubsidyStatus,
    MApproveSubsidy
},
    emits: ['update:modelValue','close','save'],
    props:{
        modelValue: Boolean|String,
        outgoing:{
            type:Boolean,
            default: true
        }
    },
    data:()=>({
        LOCALIZATION: Object.freeze(LOCALIZATION),
        sign_data: '',
        sign_dialog: false,
        approve_dialog: false,
        refuse_dialog: false,
        signed_data: '',
        user_info:'',
        json_data:{
            Content:{},
            User:{}
        },
        status:'',
        status_code:0,
        edit:false,
        loading: true,
        id:''
    }),
    // computed:{
    //     id:{
    //         get(){
    //             return this.modelValue
    //         },
    //         set(val){
    //             return this.$emit('modelValue', val)
    //         }
    //     }
    // },
    computed:{
        lang(){return this.$store.getters.LANG},
        help_messages_status(){return this.$store.getters.HELP_MESSAGES_STATUS},
        loading_progress(){
            return this.$store.getters.LOADING_PROGRESS_ALL
        }
    },
    async mounted(){
        //Если добавление
        if(this.modelValue == ''){
            this.edit = true
            
            //Получаем данные о текущем пользователе
            var obj = this.getUserInfo()
            this.json_data['User'] = JSON.parse(JSON.stringify(obj))
            this.json_data['Content'] = JSON.parse(JSON.stringify(obj))
            //Задаем значения по умолчанию    
            this.json_data['Content'] = {
                no_debit:'Да',
                summ: '15000'
            },
            //Получаем текущую дату
            this.json_data['Content']['Date'] = this.$root.currentDate()
            //Получаем документы привязаные к организации и прикрепляем к карточке
            await this.getDocs()
            this.loading = false
        }
        //Если открыта карточка
        else{
            this.id = this.modelValue
            this.getCard()
        }
        if(this.help_messages_status){
            if(this.edit == true && this.$root.help_message_current_status('MSubsidyCard') == undefined){

            
                var myTour = {
                canExit: true,
                nextText: LOCALIZATION[this.lang].hm_next,
                previousText: LOCALIZATION[this.lang].hm_previous,
                finishText: LOCALIZATION[this.lang].hm_finish,
                disableInteraction: true,
                steps: [{
                target: '#m-subsidy-card-text',
                content: LOCALIZATION[this.lang].hm_steps[0],
                }, {
                target: '#m-subsidy-card-acrions',
                content: LOCALIZATION[this.lang].hm_steps[1],
                placement: [ 'right', 'top', 'bottom', 'left' ]
                }],
                } 
                try{
                    // Tour.start(myTour).then(result=>{
                    // this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidyCard', status: true})
                    // }).catch(()=>{this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidyCard', status: true})})
                }catch{
                    // this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidyCard', status: true})
                }
                
            }
        }
    },
    watch:{
        sign_data(val){
            if(val==false && this.signed_data == ''){
                this.loading = false
            }
        },
        "json_data.Content.stay_date"(){
            if(this.edit){this.json_data['Content'].decrease_date = null}
        }
    },
    methods:{
        getDateFromTimestamp(timestamp){
            
            var date = new Date(timestamp * 1000);
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var hh = String(date.getHours()).padStart(2, '0');
            var MM = String(date.getMinutes()).padStart(2, '0');
            var ss = String(date.getSeconds()).padStart(2, '0');

            var yyyy = date.getFullYear();
            // today = dd+'.'+mm+'.'+yyyy + ' ' + hh + ':'+MM+':'+ss;
            date = dd +'.'+ mm +'.' +   yyyy + ' ' + hh +':'+MM+':'+ss;

            return date
      
        },

        // Подпись данных
        async sign(){
            this.loading = true
            var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
            var search = {id:this.id}
            //Запрос на получение данных для подписи с сервера
            await this.$store.dispatch('GET_SUBSIDIES',{list:false,search:search,with_files:false,workplace_id: workplace_id,for_sign:true}).then(response=>{
                this.loading = false
                var data = response.data.result.items[0]['Content']
                var message = null
                
                if(data.constituent_docs == null){
                    message = this.LOCALIZATION[this.lang].mes_not_constituent_docs
                }else if(data.license == null){
                    message = this.LOCALIZATION[this.lang].mes_not_license
                }else if(data.ekonak == null){
                    message = this.LOCALIZATION[this.lang].mes_not_ekonak
                }else if(data.ruming_list == null){
                    message = this.LOCALIZATION[this.lang].mes_not_ruming_list
                }

                if(message != null){
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":message,'status':'error'})
                    return null
                }

                this.sign_data = this.toBase64(response.data.result.items[0])
                this.sign_dialog = true
            },error=>{this.loading = false})
            
        },
        //Отправка подписаных данных получателю
        async send(sign){
            this.loading = true
            await this.$store.dispatch('SIGN_SUBSIDY',{id:this.id,signature:sign}).then(response=>{

                this.close()
                this.$emit('save')
                this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":response.data.message,'status':'success'})
                this.loading = false
            },error=>{
                this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
                this.loading = false
            })
        },
        // Получение данных с сервера
        async getCard(){
            this.loading = true
            var search = {id:this.id}
            var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
            //Запрос на получение данных карточки с сервера
            await this.$store.dispatch('GET_SUBSIDIES',{list:false,search:search,workplace_id: workplace_id,"outgoing":this.outgoing}).then(response=>{
                
                this.json_data = response.data.result.items[0].json_data
                this.status = response.data.result.items[0].status
                this.status_code = response.data.result.items[0].status_code
            
            },error=>{
                this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
            })
            this.loading = false
        },

        

        //Сохранение карточки документа
        async save(){
            const { valid } = await this.$refs.subsidy_form.validate()
                if(!valid){
                return false
                }
            
                this.json_data['Content']['lastname']   = this.json_data['Content']['lastname'].charAt(0).toUpperCase() +   this.json_data['Content']['lastname'].slice(1)
                this.json_data['Content']['firstname']  = this.json_data['Content']['firstname'].charAt(0).toUpperCase() +  this.json_data['Content']['firstname'].slice(1)
                if(this.json_data['Content']['patronymic']) this.json_data['Content']['patronymic'] = this.json_data['Content']['patronymic'].charAt(0).toUpperCase() + this.json_data['Content']['patronymic'].slice(1)

            var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
            if(this.id == ''){
                //Сохранение новых данных на сервере, вывод сообщения о результате выполнения


                this.$store.dispatch('ADD_SUBSIDY_V1',{json_data:this.json_data}).then(response=>{
                    this.id = response.data.result

                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":response.data.message,'status':'success'})
                    this.id = response.data.result

                    this.getCard()
                    this.$emit('save')
                    
                    this.edit = false
                },error=>{
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
                    this.loading= false
                })
            }else{
                // Изменение данных на сервере, вывод сообщения о результате выполнения
                var j_d = JSON.parse(JSON.stringify(this.json_data))
                delete j_d['history']
                this.$store.dispatch('EDIT_SUBSIDY',{id:this.id, json_data:j_d}).then(response=>{
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":response.data.message,'status':'success'})
                    this.edit = false
                    this.$emit('save')
                },error=>{
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
                    this.loading= false
                })
            
            }
            
        },

        //Получение общих документов для карточки
        async getDocs(){
            var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
            await this.$store.dispatch('GET_ORG_DOCS',{workplace_id:workplace_id,types:['uch_docs','license']}).then(response=>{
                var files = response.data.result['items']
                
                var file = files.find(elem =>{
                    return elem.file_type == 'uch_docs'
                })
                if (file != undefined) this.json_data['Content'].constituent_docs = file.files
                
                    file = files.find(elem =>{
                    return elem.file_type == 'license'
                })
                if (file != undefined)    this.json_data['Content'].license = file.files
            

            })
        },
        
        //Получаем информацию о пользователе
        getUserInfo(){
            var obj ={}
            var user_info = this.$store.getters.USER_INFO
            obj['OrgBin'] = user_info['org']['bin']
            obj['OrgName'] = user_info['org']['name']
            obj['UserIin'] = user_info['person']['iin']
            obj['UserName'] = user_info['person']['lastname'] +' '+ user_info['person']['firstname'] + ' '+ user_info['person']['patronymic']
            return obj
        },

        //Конвертировать данные в base64
        toBase64(data){
            var n_data = {}
            var keys = Object.keys(data).sort()
            for(var k_index in keys){
                var elem = Object.keys(data[keys[k_index]]).sort()

                var sdata = elem.reduce(
                (obj, key) => {
                    obj[key] = data[keys[k_index]][key];
                    return obj;
                },{})
                n_data[keys[k_index]] = sdata
            }
            console.log(n_data)
        
                var res = Base64.encode(JSON.stringify(n_data))
        
                return res
        },

        //Закрытие карточки
        close(){
            this.show = false
            
            this.addItem={
                Content:{},
                User:{}
            }
            this.$emit('close')
            this.loading = false
        },

    }
}
</script>

<style>

.card-title{
    color: #101010;

font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
</style>